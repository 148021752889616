* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: var(--tg-theme-bg-color); /* Используем фон темы Telegram */
  color: var(--tg-theme-text-color); /* Цвет текста из темы Telegram */
}

.container {
  padding: 16px; /* Немного уменьшен padding для оптимизации пространства */
  max-width: 100%; /* Максимальная ширина для адаптации на всех устройствах */
  margin: 0 auto;
  text-align: center;
}

.welcome-message {
  font-size: 22px;
  margin-bottom: 12px;
  color: var(--tg-theme-button-text-color); /* Акцентируем на тексте */
}

.description {
  font-size: 14px;
  margin-bottom: 16px;
  color: var(--tg-theme-hint-color); /* Используем цвет подсказок для описаний */
}

.loading, .error {
  color: var(--tg-theme-destructive-text-color); /* Используем системный цвет для ошибок */
}

.counters {
  margin: 16px 0;
}

.counters p {
  font-size: 16px;
  margin: 8px 0;
  color: var(--tg-theme-text-color); /* Основной цвет текста */
}

/* Новые стили */

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.action-button {
  background-color: var(--tg-theme-button-color); /* Кнопка в стиле Telegram */
  color: var(--tg-theme-button-text-color); /* Цвет текста кнопки */
  border: none;
  padding: 15px;
  margin: 10px 0;
  width: 100%;
  max-width: 300px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px; /* Добавим закругленные углы для современного стиля */
  transition: background-color 0.3s ease; /* Плавный переход при наведении */
}

/*.action-button:hover {
  background-color: var(--tg-theme-button-color-hover); */

.waiting {
  font-size: 18px;
  margin-top: 20px;
  color: var(--tg-theme-hint-color); /* Для акцента */
}

@media (max-width: 600px) {
  .action-button {
    width: 90%;
  }
}

.history-container {
  display: flex;
  justify-content: center; /* Центрируем контейнер */
  padding: 16px; /* Добавим отступы для улучшения внешнего вида */
}

.history {
  text-align: left; /* Выравнивание текста по левому краю */
  width: 100%; /* Занимает всю ширину контейнера */
  max-width: 600px; /* Максимальная ширина для более компактного отображения */
  padding-left: 16px; /* Отступ слева для визуального отделения */
  border-left: 4px solid var(--tg-theme-border-color); /* Левое обрамление для визуальной четкости */
}

.history h3 {
  margin-bottom: 10px; /* Отступ под заголовком истории */
}

.history h4 {
  margin-top: 10px; /* Отступ над заголовком даты */
  margin-bottom: 4px; /* Отступ под заголовком даты */
}

.history ul {
  list-style-type: none; /* Убираем маркированный список */
  padding-left: 0; /* Убираем отступы по умолчанию */
}

.history li {
  margin: 4px 0; /* Отступы между элементами списка */
  font-size: 14px; /* Размер шрифта для элементов списка */
}

.history li.completed::before {
  content: "✔️"; /* Галочка для завершенных действий */
  color: green; /* Задаем зелёный цвет */
  margin-right: 5px; /* Отступ между символом и текстом */
}

.section {
  margin: 16px 0; /* Отступы сверху и снизу */
  padding: 16px; /* Внутренние отступы */
  border: 1px solid var(--tg-theme-border-color); /* Рамка для разделов */
  border-radius: 8px; /* Закругленные углы */
  background-color: var(--tg-theme-bg-color); /* Цвет фона для раздела */
}
